<template>
  <div class="news">
    <top-head></top-head>
    <menu-list :menuList="menuList" @changeActive="changeActive"></menu-list>
    <news-matter :active="active"></news-matter>
    <bottom-bar></bottom-bar>
  </div>
</template>

<script>
import TopHead from "@/components/TopHead";
import BottomBar from "../../components/BottomBar.vue";
import MenuList from "@/components/MenuList";
import NewsMatter from "@/views/news/components/NewsMatter";
export default {
  name: "News",
  data() {
    return {
      active: {},
      show: false,
      menuList: [
        // {
        //   title: "青企头条",
        //   component: "NewsMatter",
        //   id: 8
        // },
        {
          title: "青企要闻",
          component: "NewsDynamic",
          id: 6
        },
        {
          title: "青企动态",
          component: "NewsActive",
          id: 7
        }
      ]
    };
  },
  methods: {
    changeActive(active) {
      this.active = active;
      this.$router.push({
        name: "News",
        params: {
          component: active.component
        }
      });
    }
  },
  components: {
    TopHead,
    BottomBar,
    MenuList,
    NewsMatter
  }
};
</script>

<style lang="less" scoped>
.content-box {
  position: relative;
}
.content {
  position: absolute;
}
.active-menu {
  box-sizing: border-box;
  padding: 0 3vw;
  height: 13vw;
  font-size: 4vw;
  color: #404040;
  background-color: #f4f4f4;
  border-bottom: 1px solid #d7d7d9;
  .arrow-icon {
    transition: all 0.3s ease-in-out;
    &.active {
      transform: rotate(-180deg);
    }
  }
}
.menu-list {
  width: 100%;
  position: absolute;
  z-index: 5;
  padding: 0 3vw;
  background-color: #f4f4f4;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
  &.active {
    max-height: 88vw;
  }
  .menu-item {
    margin: 5vw 0 10vw 0;
    font-family: PingFang-SC-Medium;
    font-size: 4vw;
    color: #404040;
    &.active-color {
      color: #007069;
    }
  }
}
</style>
