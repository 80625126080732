<template>
  <van-skeleton title :row="50" :loading="loading">
    <div class="components-content">
      <div class="news-list">
        <div
          class="news-item"
          @click="
            $router.push({
              name: 'NewsDetails',
              params: { id: topData.id, title: '新闻详情' }
            })
          "
        >
          <div class="news-photo">
            <img :src="topData.head_pic" alt="" />
          </div>
          <div class="news-title">
            {{ topData.title }}
          </div>
          <div
            class="news-intro"
            v-if="topData.content"
            v-html="topData.content.replace(/<[^>]+>/g, '')"
          ></div>
          <div class="news-time-bar frcb">
            <div class="news-time">
              {{ topData.create_time }}
            </div>
            <div class="more-box">
              <div class="more frc">
                更多
                <div class="more-right-arrow">
                  <img src="@/assets/images/news_right_arrow.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
        >
          <div
            class="news-list-item"
            v-for="(item, index) in dataList"
            :key="index"
            @click="
              $router.push({
                name: 'NewsDetails',
                params: { id: item.id, title: '要闻详情' }
              })
            "
          >
            <div class="news-photo">
              <img :src="item.head_pic" />
            </div>
            <div class="news-title">
              {{ item.title }}
            </div>
            <div
              class="news-intro"
              v-if="item.content"
              v-html="item.content.replace(/<[^>]+>/g, '')"
            ></div>
            <div class="news-time">
              {{ item.time }}
            </div>
            <div class="news-intro">
              {{ item.intro }}
            </div>
            <div class="more-box">
              <div class="more frc">
                <div>
                  更多
                </div>
                <div class="more-right-arrow">
                  <img src="@/assets/images/news_right_arrow.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </van-skeleton>
</template>

<script>
import { fetchNews } from "@/api/news";
export default {
  name: "NewsMatter",
  props: {
    active: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dataList: [],
      topData: {},
      loading: false,
      finished: false,
      params: {
        cate_id: 6,
        pageSize: 999999,
        pageNo: 1
      }
    };
  },
  watch: {
    active() {
      this.dataList = [];
      this.getDataList();
    }
  },
  methods: {
    // onLoad() {
    //   this.getDataList();
    //   // 异步更新数据
    //   // setTimeout 仅做示例，真实场景中一般为 ajax 请求
    // },
    async getDataList() {
      this.params.cate_id = this.active.id;
      const { result } = await fetchNews(this.params);
      this.dataList.push(...result.data);
      console.log(this.dataList[0].content)
      this.topData = this.dataList[0];
      this.loading = false;
      if (this.dataList.length >= result.totalCount) {
        this.finished = true;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.news-list {
  .news-item {
    background: #f2fbfa;
    .news-title {
      padding: 5vw 3vw 7vw 3vw;
      font-family: PingFang-SC-Bold;
      font-size: 4vw;
      color: #404040;
    }
    .news-intro {
      padding: 0 3vw 0 3vw;
      font-family: PingFang-SC-Bold;
      font-size: 3vw;
      font-weight: normal;
      color: #838385;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
    .news-time-bar {
      padding: 5vw 3vw 7vw 3vw;
      .news-time {
        font-family: PingFang-SC-Regular;
        font-size: 3vw;
        color: #838385;
      }
    }
  }
  .more-box {
    margin-top: 4vw;
  }
  .more {
    font-family: PingFang-SC-Medium;
    font-size: 3vw;
    color: #007069;
  }
  .news-photo {
    width: 100%;
    height: 62vw;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .news-list-item {
    padding: 6vw 0;
    border-bottom: 1vw solid #f0faf9;
    .news-title {
      margin-top: 5vw;
      font-family: PingFang-SC-Bold;
      font-size: 4vw;
      color: #404040;
    }
    .news-time {
      margin-top: 4vw;
      font-family: PingFang-SC-Regular;
      font-size: 3vw;
      color: #a2a2a3;
    }
    .news-intro {
      margin-top: 3vw;
      font-family: PingFang-SC-Medium;
      font-size: 3vw;
      color: #404040;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
  }
}
.more-right-arrow {
  margin-left: 2vw;
  width: 1.5vw;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
